
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticPropsContext } from 'next';
import { NextSeo } from 'next-seo';
import { Makeswift, Page as MakeswiftPage, PageProps as MakeswiftPageProps } from '@makeswift/runtime/next';
import { fetchAllFacets } from 'pages/api/algolia/marketplace/apps/all-facets';
import { SWRConfig } from 'swr';
import { AppCarouselDocument, AppCollectionCarouselDocument, AppRowCardsDocument, AppRowDocument, MarketplaceAppOrder } from '@/generated/contentful';
import { client as contentful } from '@/lib/contentful/client';
import { getBaseUrl, serializeCacheKey } from '@/lib/contentful/utils';
import { getPageSnapshot } from '@/lib/makeswift/client';
import '@/lib/makeswift/components';
import { getAppParams } from '@/utils/apps/getAppParams';
import { pageLocales } from '@/utils/locales/page-locales';
import { generateSeoUrl } from '@/utils/seo/generateSeoUrl';
import { useUrl } from '@/utils/url/useUrl';
import { AppsProvider } from '@/components/Contentful/entries/Marketplace/Apps/AppsProvider';
type Props = {
    fallback: {
        [key: string]: unknown;
    };
} & MakeswiftPageProps;
export default function AppsIndexPage({ snapshot, fallback }: Props) {
    const url = useUrl();
    return (<>
			<SWRConfig value={{ fallback }}>
				<NextSeo canonical={generateSeoUrl(url?.href)} languageAlternates={[
            { hrefLang: 'en-au', href: `${getBaseUrl('en-AU')}/apps/` },
            { hrefLang: 'en-gb', href: `${getBaseUrl('en-GB')}/apps/` },
            { hrefLang: 'en-us', href: `${getBaseUrl('en-US')}/apps/` },
            { hrefLang: 'x-default', href: `${getBaseUrl()}/apps/` }
        ]} openGraph={{
            url: generateSeoUrl(url?.href)
        }}/>
				<AppsProvider>
					<MakeswiftPage snapshot={snapshot}/>
				</AppsProvider>
			</SWRConfig>
		</>);
}
async function getStaticProps({ previewData, preview, locale }: GetStaticPropsContext<{
    slug: string;
}, {
    makeswift: boolean;
}>) {
    if (!pageLocales.apps?.includes(locale as Locale)) {
        console.warn('Locale not supported for apps');
        return { notFound: true, revalidate: 3600 };
    }
    const client = contentful({ preview: Boolean(preview) });
    const snapshot = await getPageSnapshot({
        pathname: '/apps',
        isMakeswiftPreview: previewData?.makeswift == true,
        previewData,
        locale,
        timeout: {
            ms: 10000,
            message: `Timeout request getPageSnapshot for "/apps" – Locale: ${locale}`
        }
    });
    if (snapshot == null)
        return { notFound: true };
    const algoliaFacets = await fetchAllFacets();
    const fallback = {
        [serializeCacheKey('appCarousel', {
            AppCarouselDocument,
            limit: 16,
            where: { featured: true },
            order: MarketplaceAppOrder.FeaturedOrderAsc
        })]: await client.request(AppCarouselDocument, {
            where: {
                featured: true
            }
        }),
        [serializeCacheKey('appRowCards', { type: 'recommended' })]: await client.request(AppRowCardsDocument, { ...getAppParams('recommended') }),
        [serializeCacheKey('appRow', { type: 'top-free' })]: await client.request(AppRowDocument, {
            ...getAppParams('top-free')
        }),
        [serializeCacheKey('appRow', { type: 'top-paid' })]: await client.request(AppRowDocument, {
            ...getAppParams('top-paid')
        }),
        [serializeCacheKey('appRow', { type: 'newest' })]: await client.request(AppRowDocument, {
            ...getAppParams('newest')
        }),
        [serializeCacheKey('appCollectionCarousel')]: await client.request(AppCollectionCarouselDocument, {}),
        [serializeCacheKey('appAlgoliaFacets')]: algoliaFacets
    };
    return {
        props: {
            snapshot,
            fallback,
            previewMode: Makeswift.getPreviewMode(previewData)
        },
        revalidate: 3600
    };
}

    async function __Next_Translate__getStaticProps__196116bc3cf__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/apps/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__196116bc3cf__ as getStaticProps }
  